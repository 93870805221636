@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap");

* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.Home {
  width: 100%;

  background-color: black;
}

.topbar {
  width: 100%;
  height: 50px;
  background-color: #44d62c;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 750px) {
  .topbarcontainer h1 {
    font-family: "Bebas Neue", serif;
    font-weight: 500;
    font-size: 15px !important;
    font-style: normal;
    color: rgb(50, 50, 50);
  }

  .MainHEadingspan {
    font-family: "Bai Jamjuree", serif;
    font-size: 40px !important;
    font-weight: 900;
    color: white;
    text-transform: uppercase;
    letter-spacing: -1;
    /* line-height: 1rem !important; */
  }

  .SubHeading h2 {
    padding-top: 20px;
    font-family: "Bai Jamjuree", serif;
    font-size: 40px !important;
    font-weight: 500;
    color: #44d62c;
    text-transform: uppercase;
    letter-spacing: -1;
    line-height: 1em !important;
    text-align: center;
    padding-bottom: 10px;
  }

  .MainHEadingspan {
    padding-top: 30px !important;
  }

  .innermaincontainer {
    width: 100% !important;
    padding: 0px !important;
    margin: 0px !important;
  }

  .MainHEadingspan1 {
    font-size: 40px !important;
  }

  .SubHeadingspan1 {
    font-size: 25px !important;
    padding-top: 10px !important;
  }
}

.topbarcontainer h1 {
  font-family: "Bebas Neue", serif;
  font-weight: 500;
  font-size: 22px;
  font-style: normal;
  color: rgb(50, 50, 50);
}

.MainContainer {
  max-width: 1170px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MainHEadingspan {
  font-family: "Bai Jamjuree", serif;
  font-size: 80px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  letter-spacing: -1;
  line-height: 1.2em;
}

.MainHEadingspan1 {
  font-family: "Bai Jamjuree", serif;
  font-size: 70px;
  font-weight: 900;
  color: white;
  text-transform: uppercase;
  letter-spacing: -1;
}

.innermaincontainer {
  width: 90%;
  padding: 10px;
  margin: 0px auto;
}

.MainHEadingspan {
  padding-top: 50px;
}

* {
  box-sizing: border-box;
}

.MainHEading h1 {
  font-weight: 500;
  margin: unset;
}

.SubHeading h1 {
  font-family: "Bai Jamjuree", serif;
  font-size: 59px;
  font-weight: 500;
  color: #44d62c;
  text-transform: uppercase;
  letter-spacing: -1;
  line-height: 1.5em;
}

.SubHeadingspan1 {
  font-family: "Bai Jamjuree", serif;
  font-size: 42px;
  font-weight: 500;
  color: #44d62c;
  text-transform: uppercase;
  letter-spacing: -1;
}

* {
  box-sizing: border-box;
}

.SubHeading h1 {
  font-weight: 500;
  margin: unset;
}

@media screen and (min-width: 0px) and (max-width: 480px) {
  .SubHeading h1 {
    font-size: 36px !important;
    font-weight: 500;
  }
}

.SubHeading2 {
  margin-top: 20px;
}

.SubHeading2span {
  font-family: "Roboto", serif;
  color: white;
  font-size: 22px;
  font-weight: 400;
  line-height: -3;
}

.VideoContainer {
  margin-top: 10px;
}

@media screen and (max-width: 750px) {
  .Registerbutton {
    padding: 20px !important;
  }

  .Registerbutton button {
    border: none;
    background-color: #44d62c;
    padding: 5px 50px !important;
    cursor: pointer;
  }

  .Registerbuttonspan1 {
    font-family: "Bai Jamjuree", serif;
    font-size: 25px !important;
    font-weight: 700;
    color: rgb(50, 50, 50);
    text-transform: uppercase;
  }
  .Registerbuttonspan2 {
    font-family: sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    color: rgb(0, 0, 0);
    line-height: normal;
    opacity: 0.8;
  }
}

.Registerbutton {
  padding: 40px;
}

.Registerbutton button {
  border: none;
  background-color: #44d62c;
  padding: 15px 80px;
  cursor: pointer;
}

.Registerbuttonspan1 {
  font-family: "Bai Jamjuree", serif;
  font-size: 50px;
  font-weight: 700;
  color: rgb(50, 50, 50);
  text-transform: uppercase;
}

.Registerbuttonspan2 {
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  line-height: normal;
  opacity: 0.8;
}

.SubHeading2 h3 {
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 400;
  padding: 5px 0;
  color: white;
}

.SubHeading2 h3 span {
  font-family: "Roboto";
  font-size: 22px;
  font-weight: bold;

  color: #44d62c;
}

.joinnowbottom h1 {
  font-size: "40px";
}

@media screen and (max-width: 750px) {
  .infocontainer1 {
    width: 100%;
    border: none;
    box-sizing: border-box;
    margin: 0px auto;
    margin-bottom: 30px !important;
    margin-top: 20px !important;
    padding: 0px 10px;
    display: flex;
    flex-direction: column !important;
  }

  .infocontainer1left {
    width: 100% !important;
    flex: 1 1 auto;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .infocontainer1right {
    width: 100% !important;
    flex: 1 1 auto;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .infocontainer2 {
    width: 100%;
    border: none;
    box-sizing: border-box;
    margin: 0px auto;
    margin-bottom: 60px;
    margin-top: 30px !important;
    padding: 0px 10px;
    display: flex;
    flex-direction: column-reverse !important;
  }

  .infocontainer2left {
    width: 100% !important;
    flex: 1 1 auto;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .infocontainer2right {
    width: 100% !important;
    flex: 1 1 auto;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .joinnowbottom h1 {
    font-size: "20px" !important;
  }

  .joinnowbottom {
    font-family: "Bai Jamjuree", serif;
    font-size: 12px !important;
    font-weight: 500;
    color: rgb(255, 255, 255);
    text-transform: uppercase;
    letter-spacing: -1;
  }

  .Fottercontainer img {
    width: 250px;
    margin-top: 40px !important;
  }
  .react-responsive-modal-modal {
    margin: 0 !important;
  }

  /* .reactmodelcalendar {
    width: "100%" !important;
  }

  .iframecalendar {
    width: "100%" !important;
    height: "100%" !important;
  } */
}

/* .reactmodelcalendar {
  width: "600px";
  height: "700px";
}

.iframecalendar {
  width: 700px;
  height: 500px;
} */
.infocontainer1 {
  width: 100%;
  border: none;
  box-sizing: border-box;
  margin: 0px auto;
  margin-bottom: 60px;
  margin-top: 60px;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
}

.infocontainer1left {
  width: 33.3%;
  flex: 1 1 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.infocontainer1right {
  width: 66.7%;
  flex: 1 1 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.infoimagecontainer {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}
.infoimagecontainer img {
  max-width: 100%;
}

.InfoContainerData {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px 10px 0px;
}

.InfoContainerDatah1 {
  font-family: "oswald";
  font-size: 34px;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  text-align: left;
}

.InfoContainerDatah1 span {
  color: #44d62c;
}

.InfoContainerDatah2 {
  font-size: 22px;
  font-family: "Bai Jamjuree", serif;
  color: white;
  text-align: left;
}

.InfoContainerDatap {
  color: white;
  font-family: "Roboto";
  font-size: 20px;
  text-align: left;
  margin-top: 20px;
  line-height: 1.4em;
  font-weight: 600;
}

.InfoContainerDataul {
  margin-top: 20px;
  list-style: none;
}
.InfoContainerDataul li:before {
  content: "✓ ";
  color: #44d62c;
}

.InfoContainerDatali {
  font-family: "Roboto";
  margin: unset;
  font-size: 20px;
  color: white;
  text-align: left;
  line-height: 1.7em;
  font-weight: 600;
}

.InfoContainerDatali span {
  color: #44d62c;
}

.infocontainer2 {
  width: 100%;
  border: none;
  box-sizing: border-box;
  margin: 0px auto;
  margin-bottom: 60px;
  margin-top: 60px;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
}

.infocontainer2left {
  width: 66.7%;
  flex: 1 1 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.infocontainer2right {
  width: 33.3%;
  flex: 1 1 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.InfoContainerDatap span {
  color: #44d62c;
}

.joinnowbottom {
  font-family: "Bai Jamjuree", serif;
  font-size: 25px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  letter-spacing: -1;
}

.SubHeadingspan span {
  color: #44d62c;
}

.Fottercontainer h1 {
  font-size: 22px;
  font-weight: 500;
  font-family: "roboto";
  margin: unset;
  color: white;
}
.Fottercontainer h1 span {
  color: #44d62c;
}

.Fottercontainer img {
  width: 250px;
  margin-top: 120px;
}

.Fottercontainer p {
  font-family: "roboto";
  font-size: 14px;
  color: rgb(219, 219, 219);
}

.Fottercontainer h2 {
  margin-top: 20px;
  font-family: "roboto";
  font-size: 13px !important;
  color: rgb(255, 255, 255);
}
.Fottercontainer h2 span {
  margin-top: 20px;
  font-family: "roboto";
  font-size: 13px !important;
  color: #188bf6;
  cursor: pointer;
  font-weight: 400;
}

.Fottercontainer h3 {
  margin-top: 20px;
  font-family: "roboto";
  font-size: 13px !important;
  color: rgb(255, 255, 255);
}
